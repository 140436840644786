.cursor-pointer {
  cursor: pointer;
}

.max-w-100-p {
  max-width: 100px;
}

.live {
  position: relative;
  padding-left: 24px;
}

.live::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: red;
  position: absolute;
  border-radius: 50%;
  margin-left: -24px;
  animation: live 1s ease-in-out infinite;
}

@keyframes live {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.icon {
  position: relative;
}


.icon:first-child:after {
  content: "New";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  z-index: 100;
  color: red;
  text-shadow: 1px 1px 3px black;
  transform: rotate(-45deg);
}

.footer {
  display: flex;
  justify-content: space-evenly;
  padding: 8px 0;

  margin-top: 12px;
  .item {
    display: flex;
    align-items: center;
    img {
      width: 50px;

      margin-right: 4px;
    }
  }
}